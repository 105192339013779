import * as React from 'react'
import ComingSoon from '../components/coming-soon/coming-soon'

const BlogPage = () => {
    return (
        <ComingSoon></ComingSoon>
    )
  }
export default Object.assign(BlogPage, {
      pageTitle: 'Blog'
  })